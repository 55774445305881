import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { CAREERS_LINK } from 'src/constants';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 !important',
    background: '#F7F7F7',
    [breakpoint.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${spacing(10)} ${spacing(20)}`,
    width: '50%',
    [breakpoint.down('md')]: {
      width: '100%',
      padding: `${spacing(10)} ${spacing(5)}`,
    },
  },
  text: {
    maxWidth: pxToRem(550),
  },
  button: {
    marginTop: spacing(7),
    [breakpoint.down('md')]: {
      width: '100%',
    },
  },
  img: {
    height: '50vw',
    maxHeight: '60vh',
    width: '50%',
    [breakpoint.down('md')]: {
      width: '100%',
      height: '60vw',
    },
  },
});

const JoinOurTeam: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <div className={classes.content}>
        <div className={classes.text}>
          <Typography variant="h2" color="blue" underline>
            Level up. Join our team!
          </Typography>
          <Typography variant="p1" color="blackish" style={{ marginTop: pxToRem(32) }}>
            Ready to join our collective? We&apos;re looking for people with
            {' '}
            huge hearts, a strong sense of empathy, and a passion to uplift
            {' '}
            thousands of small businesses.
          </Typography>
          <Button
            variant="filled"
            color="teal"
            href={CAREERS_LINK}
            isExternal
            className={classes.button}
          >
            See open positions
          </Button>
        </div>
      </div>
      {React.cloneElement(SectionImages[4], {
        className: classes.img,
      })}
    </SectionContainer>
  );
};

export default JoinOurTeam;
