import React from 'react';
import { createUseStyles } from 'react-jss';
import { breakpoint, pxToRem, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    background: 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #F7F7F7',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(185.87deg, #13266D 25.84%, #00B6B6 117.58%), #F7F7F7',
    },
    [breakpoint.up('md')]: {
      padding: `${spacing(20)} 4vw`,
    },
    [breakpoint.down('md')]: {
      padding: '8vw 4vw',
    },
  },
});

const ContactUs: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <Typography variant="h2" color="white" underline centered>
        Contact us!
      </Typography>
      <Typography variant="p1" color="white" className="mt-10 mx-auto" style={{ maxWidth: pxToRem(850) }}>
        Have a question, comment, or concern? Send us an email at hello@bizwise.com.
        {' '}
        We&apos;re happy to chat or listen to feedback.
      </Typography>
    </SectionContainer>
  );
};

export default ContactUs;
