import React from 'react';
import { createUseStyles } from 'react-jss';
import { breakpoint, pxToRem, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
  },
  content: {
    padding: `${spacing(10)} ${spacing(20)}`,
    width: '50%',
    [breakpoint.down('md')]: {
      width: '100%',
      padding: `${spacing(10)} ${spacing(0)}`,
    },
  },
  img: {
    height: '35vw',
    maxHeight: pxToRem(500),
    width: '50%',
    [breakpoint.down('md')]: {
      width: '100%',
      height: '60vw',
    },
  },
});

const WhatGuidesUs: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      {React.cloneElement(SectionImages[3], {
        className: classes.img,
      })}
      <div className={classes.content}>
        <Typography variant="h2" color="blue" underline>
          What Guides Us
        </Typography>
        <Typography variant="p1" color="blackish" style={{ marginTop: spacing(8) }}>
          At Bizwise, we are well aware of the difficulties of running a new business.
          {' '}
          81% of small business owners work nights and ultimately work more than the average
          {' '}
          person&apos;s 9-5. Our job is to make your business and your life better.
        </Typography>
      </div>
    </SectionContainer>
  );
};

export default WhatGuidesUs;
