import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(650),
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    paddingLeft: '15%',
    paddingTop: spacing(20),
    paddingBottom: spacing(20),
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoint.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
      paddingTop: spacing(2),
      paddingBottom: spacing(15),
    },
    [breakpoint.down('sm')]: {
      paddingLeft: spacing(6),
      paddingTop: spacing(2),
      paddingBottom: spacing(15),
    },
  },
  contentBackground: {
    background: 'linear-gradient(119.13deg, #13266D 47.32%, #5FBCFF 163.26%)',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(98.13deg, #13266D -14.77%, #5FBCFF 293.94%), #13296B',
    },
  },
  text: {
    width: pxToRem(320),
    marginTop: spacing(2),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SlantedSection
      img={SectionImages[2]}
      contentWidth={0.4}
      className={classes.container}
      contentClassName={classes.contentBackground}
      showNavbarGradient
      backgroundShape={5}
    >
      <div className={classes.content}>
        <Typography variant="h1" color="white">
          About Us
        </Typography>
        <Typography variant="p1" color="white" className={classes.text}>
          The Bizwise team is a collective of small business lovers,
          {' '}
          here to help evolve your business.
        </Typography>
      </div>
    </SlantedSection>
  );
};

export default Hero;
