import React from 'react';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint } from 'src/theme';
import SectionContainer, { Props as SectionContainerProps } from 'src/sections/shared/SectionContainer';

const mobileSlantAmount = 0.08;
const mobilePictureHeight = pxToRem(380);

// We are avoiding the use of parameterized class names to avoid hydration bugs with react-jss
const useStyles = createUseStyles({
  container: {
    padding: '0 !important',
    display: 'flex',
    [breakpoint.down('md')]: {
      flexDirection: 'column-reverse !important',
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    zIndex: 2,
    [breakpoint.down('md')]: {
      width: '100% !important',
      padding: `${mobileSlantAmount * 100}vh 0 0 0 !important`,
      margin: `-${mobileSlantAmount * 100}vh 0 0 0 !important`,
      justifyContent: 'flex-start !important',
    },
  },
  contentRight: {
    [breakpoint.down('md')]: {
      clipPath: `polygon(0 ${mobileSlantAmount * 100}vh, 100% 0, 100% 100%, 0 100%) !important`,
    },
  },
  contentLeft: {
    [breakpoint.down('md')]: {
      clipPath: `polygon(0 0, 100% ${mobileSlantAmount * 100}vh, 100% 100%, 0 100%) !important`,
    },
  },
  imgContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    zIndex: 0,
    [breakpoint.down('md')]: {
      position: 'relative',
      width: '100% !important',
      marginLeft: 0,
      height: mobilePictureHeight,
    },
    // Safari fix
    '& *': {
      zIndex: 0,
    },
  },
  navbarGradient: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    height: pxToRem(220),
    background: 'linear-gradient(180deg, #000000 -28.18%, rgba(60, 60, 60, 0) 100%)',
  },
});

interface Props extends SectionContainerProps {
  contentClassName?: string,
  img: React.ReactElement,
  imgLeft?: boolean,
  contentWidth: number,
  slantAmount?: number,
  showNavbarGradient?: boolean,
}

const SlantedSection: React.FC<Props> = ({
  children,
  contentClassName,
  className,
  style,
  img,
  imgLeft = false,
  contentWidth,
  slantAmount = 0.2,
  showNavbarGradient = false,
  ...sectionContainerProps
}) => {
  const classes = useStyles();
  const slantOverlap = contentWidth * slantAmount * 100;
  return (
    <SectionContainer
      className={cx(classes.container, className)}
      style={{
        ...style,
        flexDirection: imgLeft ? 'row-reverse' : 'row',
      }}
      backgroundShapeZIndex={3}
      {...sectionContainerProps}
    >
      {showNavbarGradient && <div className={classes.navbarGradient} />}
      <div
        className={cx(
          contentClassName,
          classes.content,
          imgLeft && classes.contentRight,
          !imgLeft && classes.contentLeft,
        )}
        style={{
          display: 'flex',
          alignItems: 'center',
          // boxSizing: 'content-box',
          clipPath: imgLeft
            ? `polygon(${(slantAmount) * 100}% 0%, 100% 0, 100% 100%, 0 100%)`
            : `polygon(0 0, 100% 0, ${(1 - slantAmount) * 100}% 100%, 0 100%)`,
          position: 'relative',
          width: `${contentWidth * 100}%`,
          marginRight: imgLeft ? 0 : `-${slantOverlap}%`,
          marginLeft: imgLeft ? `-${slantOverlap}%` : 0,
          paddingRight: imgLeft ? 0 : `${slantOverlap}%`,
          paddingLeft: imgLeft ? `${slantOverlap}%` : 0,
        }}
      >
        {children}
      </div>
      {React.cloneElement(img, {
        className: classes.imgContainer,
        style: {
          width: `${(1 + (slantAmount * contentWidth) - contentWidth) * 100}%`,
          left: imgLeft ? 0 : undefined,
          right: imgLeft ? undefined : 0,
          objectFit: 'cover',
        },
      })}
    </SectionContainer>
  );
};

export default SlantedSection;
