import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, spacing, breakpoint } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { assetLink } from 'src/utils';
import { LocationImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    background: 'linear-gradient(291.63deg, #13266D -10.91%, #244BA3 89.17%)',
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
  },
  locations: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    marginTop: spacing(10),
    margin: `${spacing(-7.5)} ${spacing(-7.5)}`,
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: `${spacing(7.5)} ${spacing(7.5)}`,
    width: pxToRem(275),
    [breakpoint.down('sm')]: {
      width: pxToRem(150),
    },
  },
  location: {
    marginBottom: spacing(7.5),
    borderRadius: '50%',
    height: pxToRem(200),
    width: pxToRem(200),
    [breakpoint.down('sm')]: {
      height: pxToRem(71),
      width: pxToRem(71),
    },
  },
  pin: {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    [breakpoint.down('sm')]: {
      height: pxToRem(15),
      width: pxToRem(15),
    },
  },
});

const locations: {
  name: string,
  address: string,
  img: React.ReactElement,
}[] = [
  {
    name: 'Bizwise HQ',
    address: '44 Tehama St. 4th floor, San Francisco, CA 94105',
    img: LocationImages[0],
  },
  {
    name: 'Bizwise Austin',
    address: '119 Nueces St. Austin, TX 78701',
    img: LocationImages[1],
  },
  {
    name: 'Bizwise Boulder',
    address: '1023 Walnut Street, Boulder, CO 80302',
    img: LocationImages[2],
  },
  {
    name: 'Bizwise Denver',
    address: '1533 Platte Street, Denver, CO 80202',
    img: LocationImages[3],
  },
  {
    name: 'Bizwise Los Angeles',
    address: '1221 2nd Street, 4th Floor, Santa Monica, CA 90401',
    img: LocationImages[4],
  },
  {
    name: 'Bizwise New York',
    address: '109 Nassau Street, 4th Floor, New York, NY 10038',
    img: LocationImages[5],
  },
  {
    name: 'Bizwise Phoenix',
    address: '515 E Grant St. Phoenix, AZ 85004',
    img: LocationImages[6],
  },
  {
    name: 'Bizwise Seattle',
    address: '111 S Jackson St. Seattle, WA 98104',
    img: LocationImages[7],
  },
];

const WhereWeWork: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container} backgroundShape={4} backgroundShapeBlendMode="soft-light">
      <Typography variant="h2" color="white" underline centered>
        Where we work
      </Typography>
      <div className={classes.locations}>
        {locations.map((location) => (
          <div className={classes.locationContainer} key={location.name}>
            <div className="relative">
              {React.cloneElement(location.img, {
                className: classes.location,
                objectFit: 'center',
                imgClassName: 'rounded-[50%]',
              })}
              <img src={assetLink('landing-ui/icons/BizwisePinIcon.svg')} alt="Pin Icon" className={classes.pin} />
            </div>
            <Typography variant="p1" color="white" centered style={{ fontWeight: 600 }}>
              {location.name}
            </Typography>
            <Typography variant="p1" color="white" centered>
              {location.address}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default WhereWeWork;
