import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  pxToRem, spacing, useIsMobile,
} from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { assetLink } from 'src/utils';

const useStyles = createUseStyles({
  icon: {
    marginRight: spacing(7.5),
    width: pxToRem(80),
  },
  quote: {
    marginTop: spacing(3),
    marginBottom: spacing(6),
  },
  quoteAuthor: {
    fontWeight: 600,
    fontStyle: 'italic',
  },
});

const values: {
  label: string,
  quote: string,
  iconSrc: string,
}[] = [
  {
    label: 'Individual Thinking',
    quote: 'We celebrate the individual; At Bizwise, everybody\'s opinions matter and we strive to create an environment where nobody should be afraid to voice dissenting opinions.',
    iconSrc: assetLink('BizwiseLightbulbIcon.svg'),
  },
  {
    label: 'Be Explicit',
    quote: 'We believe in having open, explicit, honest, and direct conversations at Bizwise, because people with accurate information make better and faster decisions.',
    iconSrc: assetLink('BizwiseLoudIcon.svg'),
  },
  {
    label: 'Ownership',
    quote: 'We want to be proud of everything we build at Bizwise and celebrate people who go above and beyond to find ways to improve the product.',
    iconSrc: assetLink('BizwisePersonStarIcon.svg'),
  },
  {
    label: 'Drive',
    quote: 'Nothing at Bizwise is someone else\'s problem; If there\'s an issue to be fixed or opportunity to make an impact, we become the change we want to see.',
    iconSrc: assetLink('BizwiseRocketIcon.svg'),
  },
];

const OurValues: React.FC = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <SectionContainer background="light-blue">
      <Typography variant="h2" color="blue" centered={!isMobile} underline>
        Our Values
      </Typography>
      <Typography
        variant="p1"
        color="blackish"
        centered={!isMobile}
        style={{
          marginTop: pxToRem(40),
          maxWidth: pxToRem(700),
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        Our values are our DNA.
        {' '}
        These are the fundamental beliefs and principles that guide us daily.
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-12 mt-10 md:mt-10">
        {values.map(({
          label, quote, iconSrc,
        }) => (
          <div className="flex items-start" key={label}>
            <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
            <div style={{ maxWidth: pxToRem(430) }}>
              <Typography variant="h3" color="blue">
                {label}
              </Typography>
              <Typography variant="p1" color="blackish" className={classes.quote}>
                {quote}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default OurValues;
