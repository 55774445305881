import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/About/Hero';
import WhatGuidesUsSection from 'src/sections/About/WhatGuidesUs';
import OurValuesSection from 'src/sections/About/OurValues';
import WhereWeWorkSection from 'src/sections/About/WhereWeWork';
import JoinOurTeamSection from 'src/sections/About/JoinOurTeam';
import ContactUsSection from 'src/sections/About/ContactUs';
import ApiWrapper from 'src/components/ApiWrapper';

const AboutPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo
        title="About Us - Bizwise"
        description="We are a collective of small business lovers, here to help evolve yours. Learn more about how we work."
      />
      <HeroSection />
      <WhatGuidesUsSection />
      <OurValuesSection />
      <WhereWeWorkSection />
      <JoinOurTeamSection />
      <ContactUsSection />
    </Layout>
  </ApiWrapper>
);

export default AboutPage;
